import React from 'react'
import NavListItem from '../component/NavListItem'
import NavListData from '../data/NavLinkData'
import './header.css'

function Header() {
  return (
    <header>
        <a href="/" className="logo">Wow Movies</a>
        <ul className="nav">

            {
                NavListData.map(nav =>(
                    <NavListItem key={nav._id} nav={nav}/>
                ))
            }
          
        </ul>

    </header>
  )
}

export default Header
