const NavListData =[

{
    _id:1,
    link:'#',
    name: `home`,
    active:true,
},
{
    _id:2,
    link:'#schedule',
    name: `schedule`,
    active:false,
},

{
    _id:3,
    link:'#download',
    name: `download`,
    active:false,
},

]

export default NavListData;