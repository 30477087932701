import React, {useState, useEffect} from 'react'
import './banner.css';

import MovieContent from '../component/MovieContent';
import MovieDate from '../component/MovieDate';
import PlayBtn from '../component/PlayBtn';
import MovieSwiper from '../component/MovieSwiper';




function Banner(async) {

    const [movie, setMovie] = useState([]);



      
      useEffect(()=>{   
        fetchInfo()
        // checkCookieEnable()
      },[])


      // const checkCookieEnable =()=>{
      //   document.cookie = "s=1; SameSite=None; Secure";
      
      //   var msg = /s=1/.test(document.cookie)
      //   let iscookieEnable = (window.navigator.cookieEnabled) ? true : false;
      //   window.parent.postMessage(msg, "*");
      //   console.log(msg)
      //   console.log(document.cookie)
      // }



      const url = 'https://parivartank8.github.io/TodayNews-server/z-wow-movies.json';

      async function fetchInfo (){
        let newData = await fetch(url)
        let parsedData = await newData.json()
        setMovie(parsedData);
      }



      const handleSlideChange = id =>{
        const newMovie = movie.map(movie=>{
          movie.active=false;
          if(movie._id === id){
            movie.active=true;
          }
          return movie;
        });
        setMovie(newMovie)
      }


  return (

    <>

    <div className='banner'>

     {
      movie && movie.length>0 && movie.map(movie=>(
        <div className='movie' key={movie._id}>
        <img src={movie.bgImg} alt="backGround Image" className={`bgImg ${movie.active ? 'active' : undefined}`} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <MovieContent  movie={movie} />
            </div>

            <div className="col-lg-6 col-md-12">
              <MovieDate movie={movie} />
              <PlayBtn movie={movie} />
            </div>
          </div>
        </div>
      </div>
      ))
     }

   
      
      {
      
        movie && movie.length >0 && <MovieSwiper  slides={movie} slidesChange={handleSlideChange}/>

        
      }
      
    </div>

    <div className="instruction">
      <a href="#info-2">
      <button>
      Click here if having problems with loading Images/data.
      </button>
      </a>
    </div>
    </>
  )
}

export default Banner
