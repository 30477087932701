import React, {useState, useEffect} from 'react'
import Card from '../component/Card';
import './schedule.css'
// import axios from 'axios';
import Download from './Download';


function Schedule_2() {

    const filterList =[
        {_id:1, name:'All', active: true},
        {_id:2, name:'Romance', active: false},
        {_id:3, name:'Action', active: false},
        {_id:4, name:'Thriller', active: false},
        {_id:5, name:'Horror', active: false},
        {_id:6, name:'Adventure', active: false},
    ];



    const [data, setData] = useState([]);
    const [movies, setMovies] = useState([]);
    const [filters , setFilter] = useState(filterList);
    const [query, setQuery] = useState("");
    const [getId , setId] = useState('')



    const getCardId = (movieId) =>{
        setId(movieId);
        
    }
   


 

    const url = 'https://parivartank8.github.io/TodayNews-server/z-wow-movies.json';

    //   const fetchData = () => { 
    //     return axios.get(url).then((res) => setData(res.data))
    //   }

      const fetchData= async()=>{
        let newData = await fetch(url)
        let parsedData = await newData.json()
        setData(parsedData)
      }

    useEffect(()=>{
        fetchData();
    },[]);

    useEffect(()=>{
        setMovies(data);
    },[data]);


   


    const handleFilterMovies =category =>{

        setFilter(filters.map(filter=>{
            filter.active=false;
            if(filter.name === category){
                filter.active=true
            }
            return filter;
        }))

        if(category==='All'){
            setMovies(data)
            return
        }
       setMovies(data.filter(movie => movie.category === category))
  
    }





  return (
    <section id="schedule" style={{transition:'smooth'}} className='schedule' >
        <div  className="container-fluid" >
            <div  className="row">
                <h4 className='section-title'> All Movies</h4>
            </div>

            <div  className="row sections-card">
                
                    { 
                        <ul  className="filters">
                            {
                                filters.map(filter =>(
                                    <li key={filter.id} className={`${filter.active ? 'active' : undefined}`}
                                    onClick={()=>{handleFilterMovies(filter.name)}}
                                    >{filter.name}</li>
                                ) )

                                
                            }
                            <div  className='search'  >  
                                <input  type='text' onChange={event => setQuery(event.target.value)} placeholder='Search..'/>
                                <ion-icon name="search-outline"></ion-icon>
                         
                            </div >
                        </ul >
                        
                    }

            </div>

            <div className="row mt-5">
                {movies && movies.length>0 && movies.filter(
                    movie=>{
                        movie.active = false;
                        if(query===''){
                            return movie;
                        }
                        else if(movie.title.toLowerCase().includes(query.toLocaleLowerCase())){
                            return movie;
                        }
                        
                    }
                ).map(movie =>(<>
                        
                        <Card key={movie._id} movie={movie}  getCardId={getCardId}/>
                    </>
                ))}
            </div>
        </div>



        <Download movie={movies} getId={getId} />
    </section>
    
  )
}

export default Schedule_2
