
import React from 'react'
import './movieContent.css'

import Button from './Button'


function MovieContent({movie}) {

  return (
    <div className={`content ${movie.active ? 'active' : undefined}`}>
                <img src={movie.titleImg} alt="Movie Title" className="movie-title" />
                <h4><span>{movie.year}</span>
                <span><i>{movie.ageLimit}</i></span>
                <span >{movie.length}</span>
                <span>{movie.category}</span>
                </h4>
                <p className='description'>{movie.description.slice(0,270)}</p>

                <a href="#download">
                  <div className="button">
                    <Button icon={<ion-icon name="cloud-download-outline"></ion-icon>}
                        name='Download' />
                  </div>
                </a>
    </div>
  )
}

export default MovieContent
