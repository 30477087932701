import React from 'react'
import './footer.css'

function Footer() {
  return (
    <div className='footer-last'>
      <span>Copyright © 2024 Wow Movies by <a href="https://news-today.live" target='_blank'>News Today</a></span>
    </div>
  )
}

export default Footer
