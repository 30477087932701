import React from 'react'
import './card.css'
import Download from '../pages/Download'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import '../pages/download.css'

function Card({movie, getCardId}) {

  const [movies, setMovies] = useState([movie]);
  const [visible, setVisible] = useState(false);
  const [getwidth, setWidth] = useState('');

  // const [activeId, setActiveId] = useState([movie._id])

  useEffect(()=>{
    setMovies(movie);
},[movies]);



  useEffect(()=>{
    setVisible();
},[movies]);



   const changeId=()=>{
    getCardId(movie._id);


    window.location.href = '/#download'
    

  }



  return (
    <>
    
    <div className='col-lg-2 col-md-4 col-sm-6  main-card'>
        <div className="movie-card" >
            <img src={movie.previewImg} alt="Preview" className='img-fluid' />
            <p>{movie.length} | {movie.category}</p>
        
            <div className="content" >
                <h4>{movie.title}</h4>
              
                
                <div className="card-icons">
                <ion-icon name="download-outline"  onClick={()=>{changeId()}}></ion-icon>

                </div>
            </div>
        </div>
      
    </div>
    
    </>
  )
}

export default Card
