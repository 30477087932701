import React from 'react'
import './download.css'
import photo1 from '../image/wow_Movies.png'
import photo2 from '../image/WM.png'
import trailerGif from '../image/trailerGif.gif'
import { useState, useEffect } from 'react'


function Download({ movie, getId}) {

  const newMovie = movie

  let newId = getId
  let count = 0;


  const [getMovieTitle, setMovieTitle] = useState('')
  const [getMovieInfo, setMovieInfo] = useState('')
  const [getMovieimg1, setMovieimg1] = useState('')
  const [getMovieimg2, setMovieimg2] = useState('')
  const [getMovieTrailer, setMovieTrailer] = useState(null)
  const [getMovieBudget, setMovieBudget] = useState(null)
  const [getMovieCast, setMovieCast] = useState(null)
  const [getTeraLink, setTeraLink] = useState(null)
  const [getDriveLink, setDriveLink] = useState(null)


 

  useEffect(()=>{
    getDownload();
},[newId]);



const getDownload =()=>
{
  newMovie && newMovie.length >0 && newMovie.map(moviee=>{
  
 
  if(moviee._id === newId){
    
    
    setMovieTitle(moviee.fullTitle)
    setMovieTrailer(moviee.video)
    setMovieimg1(moviee.previewImg)
    setMovieimg2(moviee.bgImg)
    setMovieInfo(moviee.description)
    setMovieCast(moviee.cast)
    setMovieBudget(moviee.budget)
    setTeraLink(moviee.movie_Link)
    setDriveLink(moviee.google_Link)
  }
  count+=1;
})
}


  
  const infoArray= (getMovieInfo.split(' '))

const mainInfoArray = []
infoArray.map(element=>{
  mainInfoArray.push(element + ' ');
})

  
  var instruction = ['1. Please select the Movie which you want to download',
 ' 2. If you are having difficulty finding a movie, we have provided a search tab for you.',
 ' 3. All the movie information will be displayed here.',
 ' 4. To get the movie link,click on the download button. ',
  '5. Enjoy your Movie']




    return (<>
    {/* <section> */}
      <div id='download' className= {`download active`}>
      <h3 className='download-title'> DOWNLOAD</h3>

      <div className='starting' >
        <div className="title">
          <h2>{getMovieTitle}</h2>
        </div>



        <div className="trailer-div">

          <div className="iframe">

           { getMovieTrailer !=null ? 
            <iframe 
            width="920" 
            height="500" 
            src= {getMovieTrailer}
        
            title={` | Official Trailer`}
            frameBorder="0"
            allow="accelerometer;autoplay;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen>

          </iframe> :
           <img src={trailerGif} alt="trailer..." /> }
          </div>

        </div>
      
      </div>



    <div className="middle">

    <h2 style={{
      marginBottom:'20px' ,
       color:'white', 

        justifyContent: `${getMovieTrailer!=null ? 'left': 'center' } `,
        textAlign:  `${getMovieTrailer!=null ? 'left': 'center' } `,
         }} >
          {getMovieTrailer!=null? 'Synopsis ': " When you got Nothing. You got nothing to Lose " }</h2>

    <> 
    <p> {getMovieInfo!=null ? mainInfoArray.slice(0,100) : ''}</p>
      <br/>
      <div className="img">

        <img src={getMovieimg1 ? getMovieimg1: photo1 } className='imdb-img1' alt="Download Image" /><br/>
      </div>
      
      <p >{getMovieInfo!=null ? mainInfoArray.slice(100,230) : ''} </p>
      
       <p className='instruction' style={{position:  getMovieTrailer!=null ? 'absolute' : 'relative', visibility: getMovieTrailer!=null ? 'hidden' : 'visible', opacity:  getMovieTrailer!=null ? '0' : '1' }}>
        1. Please select the Movie which you want to download. <br/><br/>
        2. If you are having difficulty finding a movie, we have provided a search tab for you.<br/><br/>
        3. All the movie information will be displayed here.<br/><br/>
        4. To get the movie link,click on the download button. <br/><br/>
        5. Enjoy your Movie.
       </p>
       <br />
       <br />
       <p className='note'style={{position:  getMovieTrailer!=null ? 'absolute' : 'relative', visibility: getMovieTrailer!=null ? 'hidden' : 'visible', opacity:  getMovieTrailer!=null ? '0' : '1' }}>
         Note: Enable third party cookies on WowMovies for best experience </p>

       <p className='instruction' style={{position:  getMovieTrailer!=null ? 'absolute' : 'relative', visibility: getMovieTrailer!=null ? 'hidden' : 'visible', opacity:  getMovieTrailer!=null ? '0' : '1' }}>
        Cookies set by third parties aren't a big risk. Cookies aren't inherently dangerous,
         and they don't infect your computer with malicious viruses or malware.</p>
         
<br/>

<div className="img">
<img src={getMovieimg2 ? getMovieimg2 : photo2} className='imdb-img2' alt="Download Image" /><br/>
</div>
<p>{getMovieInfo!=null ? mainInfoArray.slice(230,370) : ''}</p>

<div className="btn-middle">
  <a href="https://www.toprevenuegate.com/bswjv7qre?key=785b9b9dd9dd4d07cd6cfd44b29a48b5" target='_blank'>
<button >
    Download Link 1
  </button></a>
</div>

<p>{getMovieInfo!=null ? mainInfoArray.slice(370,520) : ''}<br/>
</p>


<div className="btn-middle" style={{ visibility: getTeraLink!=null ? 'visible' : 'hidden', opacity:  getMovieTrailer!=null ? '1' : '0',position:  getMovieTrailer!=null ? 'relative' : 'absolute' }}>
  <a href={getTeraLink }>
<button >
    Download Link 2
  </button></a>
</div>


<p>{getMovieInfo!=null ? mainInfoArray.slice(520) : ''}</p>


<div className='btn-middle '  style={{ visibility: getTeraLink!=null ? 'visible' : 'hidden', opacity:  getMovieTrailer!=null ? '1' : '0',position:  getMovieTrailer!=null ? 'relative' : 'absolute' }}>
  <a href="https://www.toprevenuegate.com/bjbzhxs78?key=acf1deeb0a0de587d4e50d235a289d20" target='_blank'> 
<button >
    Download Link 3
  </button></a>
</div>


<h2 style={{marginBottom:'20px' , color:'white'}}> {getMovieBudget!=null? 'Movie Collection: ': " " } </h2>
<p>
{getMovieBudget!=null? getMovieBudget: " " }</p>



<div className="btn"  style={{ visibility: getTeraLink!=null ? 'visible' : 'hidden', opacity:  getMovieTrailer!=null ? '1' : '0',position:  getMovieTrailer!=null ? 'relative' : 'absolute' }}>
  <a href={getDriveLink!=null ? getDriveLink : getTeraLink}>
  <button>
    Download
  </button></a>
</div>

<h2 style={{marginBottom:'20px' , color:'white'}}>{getMovieCast!=null? 'Movie Cast:': " " } </h2>
<p>
{getMovieCast!=null? getMovieCast: " " }</p>


<div className="btn">
  <a href="https://www.toprevenuegate.com/bjbzhxs78?key=acf1deeb0a0de587d4e50d235a289d20" target='_blank'>
  <button>
    Download
  </button></a>
</div>


</>
</div>
    </div>
    {/* </section> */}
    </>)
}
// }

export default Download








