import React from 'react'
import './main.css'
import Schedule_2 from './Schedule_2'

function Main() {
  return (
    <main>
      <Schedule_2/>
    </main>
  )
}

export default Main
