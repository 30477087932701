import 'bootstrap/dist/css/bootstrap.min.css'
import 'swiper/css'
import './App.css';

import Banner from './pages/Banner';
import Header from './pages/Header';
import Main from './pages/Main';
import ScrollToTop from './pages/ScrollToTop';
import Footer from './pages/Footer';
import Instruction from './pages/instruction';



const App = ()=> {
  return (

        <>
    
  

      <Header/>
      {/* <Cookie/> */}
      <Banner/>
      
      <Main/>
      <Instruction/>
      <ScrollToTop/>
      <Footer/>

    </>
  );
}

export default App;
