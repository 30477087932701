import React from 'react'
import "./instruction.css"
import cookie1 from '../image/cookie1.png'
import cookie2 from '../image/cookie2.png'
import cookie3 from '../image/cookie3.png'

function Instruction() {
  return (
    <>
    <div id='instruction' className='instruction'>

        <div className="instruction-title">
            <h1>Instructions</h1>
        </div>
      
      <div className="info">

        <p>1. On your computer, open Chrome.</p>
        <p>2. At the top right, click More Moreand {`>`} Settings.</p>
        <p>3. Under "Privacy and security," click Site settings.</p>
        <p>4. Click Third-party cookies.</p>
        <p>5. Select an option:</p>
          <p style={{marginLeft:'40px'}}> Allow third-party cookies.</p>
            <p style={{marginLeft:'80px'}}>or</p>
          <p style={{marginLeft:'40px'}}> Block third-party cookies in Incognito mode.</p>
        <p>If you block third-party cookies, all third-party cookies from other sites are blocked unless the 
          site is allowed on your exceptions list.</p>
    </div>

    </div>
    <div id='info-2' className="info-2">

        {/* <h3>Turn on Cookies only for this site.</h3> */}
        <h3>Want to turn on cookies only for this site?</h3>
        <p className='emoji'>👇</p>

      <span>
        <h5>1. click on Site Information icon.  </h5>
        <img src={cookie1} alt="" />
      </span>

      <span>
        <h5>2. click on Cookies and site data.  </h5>
        <img src={cookie2} alt="" />
      </span>

      <span>
        <h5>3. Turn on Third-party-cookies .  </h5>
        <img src={cookie3} alt="" />
      </span>
      
    </div>
    </>
  )
}

export default Instruction
