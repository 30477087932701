import {React, useState} from 'react'
import './scrollToTop.css'
import scrollImg from '../image/up-arrow-3.png'

function ScrollToTop() {



    const [visible, setVisible] = useState(false)

    const toggleVisible = ()=>{
        const scrolled =  document.documentElement.scrollTop
        if(scrolled > 1450){
            setVisible(true)
        }
        else if(scrolled <=1450){
            setVisible(false)
        }
    }

    const scrollToTop =()=>{
        window.scrollTo({
            top:860,
            behavior: 'smooth',
        })
    }

    window.addEventListener('scroll', toggleVisible)

  return (
    <>
    <button className='scrollToTop' onClick={scrollToTop} style={{display: visible ? 'flex' : 'none'}}>
        <img className='scrollImg' alt='' src={scrollImg} />

    <span className='scrollSpan'> Scroll Up</span>
    </button>
    </>
  )
}

export default ScrollToTop
